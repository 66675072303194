import React from "react";
import ReactDOM from 'react-dom/client';
import App from "./App";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import { configureStore } from "./redux/store";
import { BrowserRouter } from "react-router-dom";
import { SocketProvider } from "./context/socket";
import { AgencyProvider } from "./context/agency";
import { ControllerProvider } from "./context/Controller";
import { ConversationProvider } from "./context/conversation";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <ControllerProvider>
    <AgencyProvider>
      <ConversationProvider>
        <SocketProvider>
          <Provider store={configureStore({})}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </Provider>
        </SocketProvider>
      </ConversationProvider>
    </AgencyProvider>
  </ControllerProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
