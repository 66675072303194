import React from "react";
import classnames from "classnames";

// hooks
// import { useRedux } from "../../hooks/index";

// hooks
import { createSelector } from "reselect";
// component
import Leftbar from "./Leftbar";
import ConversationUser from "./ConversationUser/index";
import UserProfileDetails from "./UserProfileDetails/index";
import Welcome from "./ConversationUser/Welcome";
import { useConversationInfo } from "../../context/conversation";

interface IndexProps { }
const Index = (props: IndexProps) => {
  const { active_conversation_user } = useConversationInfo()

  // get from context
  // const selectedChat = null;


  // const { isChannel } = useConversationUserType();

  return (
    <>
      <Leftbar />

      <div
        className={classnames("user-chat", "w-100", "overflow-hidden", {
          "user-chat-show": active_conversation_user,
        })}
        id="user-chat"
      >
        <div className="user-chat-overlay" id="user-chat-overlay"></div>
        {active_conversation_user !== null ? (
          <div className="chat-content d-lg-flex">
            <div className="w-100 overflow-hidden position-relative">
              <ConversationUser isChannel={true} />
            </div>
            <UserProfileDetails isChannel={true} />
          </div>
        ) : (
          <Welcome />
        )}
      </div>
    </>
  );
};

export default Index;
