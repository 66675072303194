/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  This file is used for controlling the global states of the components,
  you can customize the states for the different components here.
*/

import { createContext, useContext, useState, useMemo, useEffect } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import localforage from "localforage";
// import localStorage from "localStorage";
import { useAppServices } from "../hook/services";
// import Loader from "examples/Loader";
import { useUserInfo } from "./user";
import { useLocation, useParams } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
// import env from "../config";
// import {
//   setDarkMode,
//   setFixedNavbar,
//   setSidenavColor,
//   setTransparentSidenav,
//   setWhiteSidenav,
//   useMaterialUIController,
// } from "context";

// Material Dashboard 2 React main context
const AgencyContext = createContext();

// Setting custom name for the context which is visible on react dev tools
AgencyContext.displayName = "AgencyContext";

// Material Dashboard 2 React context provider
function AgencyProvider({ children }) {
  const Service = useAppServices();
  const [user, setUser] = useState({});
  const [agency, setAgency] = useState({});
  const [loader, setLoader] = useState(true);
  const params = useParams()
  const Update = (updates) => {
    const data = { ...agency, ...updates };
    console.log(data, "UpdateUpdate");
    setAgency(data);
  };

  const clear = () => setAgency({});
  const changeFavicon = (agency_profile) => {
    // Replace 'new-favicon.ico' with the path to your new favicon
    document.title = agency_profile?.personalinfo?.name || '...'
    const newFavicon = agency_profile?.personalinfo?.profile_image;

    // Create a new link element
    const link = document.createElement("link");
    link.rel = "icon";
    link.type = "image/x-icon";
    link.href = newFavicon;

    // Find the existing favicon link element in the head
    const head = document.head || document.getElementsByTagName("head")[0];
    const existingFavicon = document.querySelector("link[rel*='icon']");

    // If an existing favicon is found, remove it
    if (existingFavicon) {
      head.removeChild(existingFavicon);
    }

    // Append the new favicon link element to the head
    head.appendChild(link);
  };
  const value = useMemo(() => [agency, user, clear,], [agency, user, clear]);
  const getAgency = async (localAgency) => {
    const email = window.location.pathname.split('/')[1]
    const agency_id = window.location.pathname.split('/')[2]
    const { response } = await Service.agency.getagencywithuser({
      query: `agency_id=${agency_id}&email=${email}`,
    });
    if (!response) return setLoader(false);
    console.log(response, 'response')
    setAgency({ ...response.data, app_profile: response.app_profile });
    setUser({ ...response.user });
    changeFavicon(response.app_profile)
    localforage.setItem("agency", { ...response.data, app_profile: response.app_profile });
    localforage.setItem("user", { ...response.user });
    return setLoader(false);
  };

  const onLoad = () => {
    getAgency();
  };

  useEffect(onLoad, []);

  return loader ? (
    <></>
  ) : (
    <AgencyContext.Provider value={value}>{children}</AgencyContext.Provider>
  );
}

// Material Dashboard 2 React custom hook for using context
function useAgencyInfo() {
  return useContext(AgencyContext) || [];
}

// Typechecking props for the MaterialUIControllerProvider
AgencyProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AgencyProvider, useAgencyInfo };
