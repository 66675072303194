/*
pages
*/
//root
import Root from "../pages/Root/index";

// dashboard
import Dashboard from "../pages/Dashboard/index";
import StarterPage from "../pages/StarterPage/index";

interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
}


const privateRoutes: Array<RouteProps> = [
  { path: "/pages-starter", component: <StarterPage /> },
  { path: "/:email/:agency_id/:locationId", component: <Dashboard /> },
  { path: "/", component: <Root /> },
];

export { privateRoutes };
