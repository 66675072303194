/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  This file is used for controlling the global states of the components,
  you can customize the states for the different components here.
*/

import { createContext, useContext, useState, useMemo, useEffect } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// import localStorage from "localStorage";
import { useAppServices } from "../hook/services";
// import Loader from "examples/Loader";
import { useUserInfo } from "./user";
import { useAgencyInfo } from "./agency";
import Loader from "../components/Loader";
// import { useNavigate } from "react-router-dom";
// import env from "../config";
// import {
//   setDarkMode,
//   setFixedNavbar,
//   setSidenavColor,
//   setTransparentSidenav,
//   setWhiteSidenav,
//   useMaterialUIController,
// } from "context";

// Material Dashboard 2 React main context
const conversationContext = createContext();

// Setting custom name for the context which is visible on react dev tools
conversationContext.displayName = "conversationContext";

// Material Dashboard 2 React context provider
function ConversationProvider({ children }) {
  const Service = useAppServices();
  const [agency, user] = useAgencyInfo()
  const [contacts, setContacts] = useState([]);
  const [customConversation, setcustomConversation] = useState([]);
  const [active_conversation, setactive_conversation] = useState({});
  const [conversation_loader, setconversation_loader] = useState(false);
  const [active_conversation_user, setactive_conversation_user] = useState(null);
  const [loader, setLoader] = useState(true);

  const getConversation = async (query) => {
    setconversation_loader(true)
    const { response } = await Service.chat.getConversation({
      query: query,
    });
    if (response) {
      console.log(response.data, 'getConversation')
      setactive_conversation(response.data)
      setconversation_loader(false)
    } else {
      setactive_conversation({})
      setconversation_loader(false)
    }
  };
  const getCustomConversation = async () => {
    const email = window.location.pathname.split('/')[1]
    const { response } = await Service.chat.getCustomConversation({
      query: `email=${email}`,
    });
    if (response) {
      console.log(response.data, 'getConversation')
      setcustomConversation(response.data)
      setLoader(false)
    } else {
      setLoader(false)
    }
  };
  const value = { active_conversation_user, setactive_conversation_user, getConversation, active_conversation, setactive_conversation, conversation_loader, getCustomConversation, customConversation }

  const onLoad = () => {
    getCustomConversation()
  };

  useEffect(onLoad, []);

  return loader ? (
    <Loader />
  ) : (
    <conversationContext.Provider value={value}>{children}</conversationContext.Provider>
  );
}

// Material Dashboard 2 React custom hook for using context
function useConversationInfo() {
  return useContext(conversationContext) || [];
}

// Typechecking props for the MaterialUIControllerProvider
ConversationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { ConversationProvider, useConversationInfo };
