import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
} from "reactstrap";
import { useAppServices } from "../hook/services";
import { useAgencyInfo } from "../context/agency";
import { useConversationInfo } from "../context/conversation";
import { useSocket } from "../context/socket";
import { useParams } from "react-router-dom";

interface DataTypes {
  name: string | null;
  description: string | null;
}
interface InviteContactModalProps {
  isOpen: boolean;
  onClose: () => void;
  onInvite: (data: any) => void;
}
const InviteContactModal = ({
  isOpen,
  onClose,
  onInvite,
}: InviteContactModalProps) => {
  /*
  data input handeling
  */
  const Service = useAppServices()
  const [agency, user] = useAgencyInfo()
  const params = useParams()
  const socket = useSocket();
  const { getCustomConversation } = useConversationInfo()
  const [data, setData] = useState<DataTypes>({
    name: null,
    description: null,
  });
  const handleCreateChat = async () => {
    console.log(data, 'datadata')
    const payload = {
      text: data,
      isCustom: true,
      email: params.email,
      agency_id: agency._id,
      location_id: null,
      name: data.name,
      description: data.description
    }
    console.log(payload, 'payloadpayload')
    socket?.emit("createlocationconversation", payload);

    getCustomConversation()
    onClose()
    // const { response } = await Service.chat.createCustomConversation({ payload })
    // if (response) {

    // }
    // try {
    //   const response = await api.create('/chat/create-location-conversation', payload)
    //   console.log(response, 'response')
    //   onClose()
    //   onInvite(response)
    // } catch (error: any) {
    //   console.log(error, 'error')
    // }
  }
  useEffect(() => {
    setData({
      name: null,
      description: null,
    });
  }, []);

  const onChangeData = (field: "name" | "description", value: string) => {
    let modifiedData: DataTypes = { ...data };
    if (value === "") {
      modifiedData[field] = null;
    } else {
      modifiedData[field] = value;
    }
    setData(modifiedData);
  };

  /*
  validation
  */
  // const [valid, setValid] = useState<boolean>(false);
  // useEffect(() => {
  //   if (data.email !== null && data.message !== null && data.name !== null) {
  //     setValid(true);
  //   } else {
  //     setValid(false);
  //   }
  // }, [data]);
  return (
    <Modal isOpen={isOpen} toggle={onClose} tabIndex={-1} centered scrollable >
      <ModalHeader toggle={onClose} className="bg-primary">
        <div className="modal-title-custom text-white font-size-16 ">
          Create Chat
        </div>
      </ModalHeader>
      <ModalBody className="p-4">
        <Form>
          <div className="mb-3">
            <Label htmlFor="AddContactModalname-input" className="form-label">
              Name
            </Label>
            <Input
              type="text"
              className="form-control"
              id="AddContactModalname-input"
              placeholder="Enter Name"
              value={data["name"] || ""}
              onChange={(e: any) => {
                onChangeData("name", e.target.value);
              }}
            />
          </div>
          <div className="">
            <Label
              htmlFor="AddContactModal-invitemessage-input"
              className="form-label"
            >
              Chat Description
            </Label>
            <textarea
              value={data["description"] || ""}
              onChange={(e: any) => {
                onChangeData("description", e.target.value);
              }}
              className="form-control"
              rows={3}
              placeholder="Enter Description"
            ></textarea>
          </div>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button type="button" color="link" className="btn" onClick={onClose}>
          Close
        </Button>
        <Button
          type="button"
          color="primary"
          // disabled={!valid}
          onClick={handleCreateChat}
        >
          Create
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default InviteContactModal;
