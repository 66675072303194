import React from "react";
import { useConversationInfo } from "../../../context/conversation";
import { useAgencyInfo } from "../../../context/agency";

interface BasicDetailsProps {
  chatUserDetails: any;
  chatDetails: any
}
const BasicDetails = () => {
  const { active_conversation_user } = useConversationInfo()
  const [agency, user] = useAgencyInfo()

  return (
    <>
      {
        active_conversation_user?.isCustom ?
          <>
            <div className="pb-2">
              <h5 className="font-size-11 text-uppercase mb-2">Info :</h5>
              <div>
                <div className="d-flex align-items-end">
                  <div className="flex-grow-1">
                    <p className="text-muted font-size-14 mb-1">Name</p>
                  </div>
                  {/* <div className="flex-shrink-0">
            <button type="button" className="btn btn-sm btn-soft-primary">
              Edit
            </button>
          </div> */}
                </div>
                <h5 className="font-size-14">{user?.username}</h5>
              </div>

              <div className="mt-4">
                <p className="text-muted font-size-14 mb-1">Email</p>
                <h5 className="font-size-14">
                  {user.email ? user.email : "-"}
                </h5>
              </div>

              <div className="mt-4">
                <p className="text-muted font-size-14 mb-1">Location</p>
                <h5 className="font-size-14 mb-0">
                  {user.location ? user.location : "-"}
                </h5>
              </div>
            </div>
            <hr className="my-4" />

            <div className="pb-2">
              <h5 className="font-size-11 text-uppercase mb-2">Chat Details :</h5>
              <div>
                <div className="d-flex align-items-end">
                  <div className="flex-grow-1">
                    <p className="text-muted font-size-14 mb-1">Name</p>
                  </div>
                  {/* <div className="flex-shrink-0">
            <button type="button" className="btn btn-sm btn-soft-primary">
              Edit
            </button>
          </div> */}
                </div>
                <h5 className="font-size-14">{active_conversation_user.name}</h5>
              </div>

              <div className="mt-4">
                <p className="text-muted font-size-14 mb-1">Description</p>
                <h5 className="font-size-14">
                  {active_conversation_user.description}
                </h5>
              </div>
            </div>
          </>
          :
          <>
            <div className="pb-2">
              <h5 className="font-size-11 text-uppercase mb-2">Personal Info :</h5>
              <div>
                <div className="d-flex align-items-end">
                  <div className="flex-grow-1">
                    <p className="text-muted font-size-14 mb-1">Company Name</p>
                  </div>
                </div>
                <h5 className="font-size-14">{agency?.profile?.personalinfo?.name}</h5>
              </div>
              <div className="mt-4">
                <p className="text-muted font-size-14 mb-1">Email</p>
                <h5 className="font-size-14">
                  {agency?.profile?.personalinfo?.email}
                </h5>
              </div>
              <div className="mt-4">
                <p className="text-muted font-size-14 mb-1">Phone</p>
                <h5 className="font-size-14">
                  {agency?.profile?.personalinfo?.phone}
                </h5>
              </div>
              <div className="mt-4">
                <p className="text-muted font-size-14 mb-1">Website</p>
                <h5 className="font-size-14">
                  {agency?.profile?.personalinfo?.website}
                </h5>
              </div>
            </div>
            <hr className="my-4" />
            <div className="pb-2">
              <h5 className="font-size-11 text-uppercase mb-2">Address Info :</h5>
              <div>
                <div className="d-flex align-items-end">
                  <div className="flex-grow-1">
                    <p className="text-muted font-size-14 mb-1">Address</p>
                  </div>
                </div>
                <h5 className="font-size-14">{agency?.profile?.addressinfo?.address}</h5>
              </div>
              <div className="mt-4">
                <p className="text-muted font-size-14 mb-1">City</p>
                <h5 className="font-size-14">
                  {agency?.profile?.addressinfo?.city}
                </h5>
              </div>
              <div className="mt-4">
                <p className="text-muted font-size-14 mb-1">Country</p>
                <h5 className="font-size-14">
                  {agency?.profile?.addressinfo?.country}
                </h5>
              </div>
              <div className="mt-4">
                <p className="text-muted font-size-14 mb-1">State</p>
                <h5 className="font-size-14">
                  {agency?.profile?.addressinfo?.state}
                </h5>
              </div>
            </div>
            <hr className="my-4" />
            <div className="pb-4">
              <h5 className="font-size-11 text-uppercase mb-2">socail_links :</h5>
              <div className="flex">
                <a href={agency?.profile?.socail_links?.facebook}><i className='bx bxl-facebook fs-4 '></i></a>
                <a href={agency?.profile?.socail_links?.instagram}><i className='bx bxl-instagram fs-4' style={{ marginLeft: "10px" }}></i></a>
                <a href={agency?.profile?.socail_links?.linkedin}><i className='bx bxl-linkedin-square fs-4' style={{ marginLeft: "10px" }} ></i></a>
              </div>
            </div>
          </>
      }
    </>
  );
};

export default BasicDetails;
