import React, { useState } from "react";
import { TabContent, TabPane } from "reactstrap";
import { createSelector } from "reselect";
import { TABS } from "../../constants/index";
import Profile from "./Profile/index";
import Chats from "./Chats/index";
import Calls from "./Calls/index";
import Bookmark from "./Bookmark/index";
import Settings from "./Settings/index";
import { useControllerInfo } from "../../context/Controller";

interface LeftbarProps { }

const Leftbar = (props: LeftbarProps) => {
  const [active, setActiveTab] = useState<string>(TABS.USERS);
  const [ActiveTab] = useControllerInfo()
  const selectActiveTab = createSelector(
    (tab: string) => tab,
    (selectedTab) => ({
      activeTab: selectedTab,
    })
  );

  const { activeTab } = selectActiveTab(active);
  // alert(ActiveTab)
  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  return (
    <>
      {/* start chat-leftsidebar */}
      <div className="chat-leftsidebar">
        <TabContent activeTab={ActiveTab}>
          {/* Start Profile tab-pane */}
          <TabPane tabId={TABS.USERS} role="tabpanel">
            <Profile />
          </TabPane>

          <TabPane tabId={TABS.CHAT} role="tabpanel">
            <Chats />
          </TabPane>
          <TabPane tabId={TABS.CALLS} role="tabpanel">
            <Calls />
          </TabPane>

          <TabPane tabId={TABS.BOOKMARK} role="tabpanel">
            <Bookmark />
          </TabPane>

          <TabPane tabId={TABS.SETTINGS} role="tabpanel">
            <Settings />
          </TabPane>
        </TabContent>
      </div>
    </>
  );
};

export default Leftbar;
