import React, { useEffect, useState } from "react";

// hooks
import { useRedux } from "../../../hooks/index";
import { createSelector } from "reselect";
// actions
import {
  toggleUserDetailsTab,
  getChatUserConversations,
  onSendMessage,
  receiveMessage,
  readMessage,
  receiveMessageFromUser,
  deleteMessage,
  deleteUserMessages,
  toggleArchiveContact,
} from "../../../redux/actions";

// hooks
import { useProfile } from "../../../hooks";

// components
import UserHead from "./UserHead";
import Conversation from "./Conversation";
import ChatInputSection from "./ChatInputSection/index";

// interface
import { MessagesTypes } from "../../../data/messages";

// dummy data
import { pinnedTabs } from "../../../data/index";
import { useSocket } from "../../../context/socket";
import { useAgencyInfo } from "../../../context/agency";
import { useConversationInfo } from "../../../context/conversation";
import { useParams } from "react-router-dom";

interface IndexProps {
  isChannel: boolean;
}
const Index = ({ isChannel }: IndexProps) => {
  // global store
  const param = useParams()
  const { dispatch, useAppSelector } = useRedux();
  const socket = useSocket();

  const errorData = createSelector(
    (state: any) => state.Chats,
    (state) => ({
      chatUserDetails: state.chatUserDetails,
      chatUserConversations: state.chatUserConversations,
      isUserMessageSent: state.isUserMessageSent,
      isMessageDeleted: state.isMessageDeleted,
      isMessageForwarded: state.isMessageForwarded,
      isUserMessagesDeleted: state.isUserMessagesDeleted,
      isImageDeleted: state.isImageDeleted,
    })
  );
  // Inside your component
  const { chatUserDetails, chatUserConversations, isUserMessageSent, isMessageDeleted, isMessageForwarded, isUserMessagesDeleted,
    isImageDeleted } = useAppSelector(errorData);
  const onOpenUserDetails = () => {
    dispatch(toggleUserDetailsTab(true));
  };

  /*
  hooks
  */
  const { userProfile } = useProfile();
  const { active_conversation_user, getConversation, active_conversation } = useConversationInfo()

  const [agency, user] = useAgencyInfo()

  /*
  reply handeling
  */
  const [UserConversation, setUserConversation] = useState<any>(chatUserConversations)

  const [replyData, setReplyData] = useState<
    null | MessagesTypes | undefined
  >();
  const onSetReplyData = (reply: null | MessagesTypes | undefined) => {
    setReplyData(reply);
  };

  /*
  send message
  */
  const onSend = (data: any) => {
    const isCustom = active_conversation_user?.isCustom == true ? true : false
    let params: any = {
      text: data.text && data.text,
      isCustom: isCustom,
      agency_id: agency._id,
      workflow_data: {
        email: param.email,
        contactId: user.id,
        locationId: param.locationId,
        app_id: '65a7bf628c605678d064a335',
        agency_id: agency._id,
        type: active_conversation_user.type
      },
      email: active_conversation_user.type == 'user' ? param.email : null,
      location_id: active_conversation_user.type == 'location_chat' ? param.locationId : null,
      type: 'location',
      chat_id: active_conversation._id,
      time: new Date().toISOString(),
      image: data.image && data.image,
      newimage: data.newimage && data.newimage,
      attachments: data.attachments && data.attachments,
      meta: {
        receiver: agency._id,
        sender: user.id,
      },
    };
    socket?.emit("conversation", params);
    if (UserConversation?.messages?.length) {

      setUserConversation({ ...UserConversation, messages: [...UserConversation?.messages, params] })
    } else {
      setUserConversation({ ...UserConversation, messages: [params] })
    }
    console.log(params, 'params')

    if (replyData && replyData !== null) {
      params["replyOf"] = replyData;
    }

    // dispatch(onSendMessage(params));
    // if (!isChannel) {
    //   setTimeout(() => {
    //     dispatch(receiveMessage(chatUserDetails.id));
    //   }, 1000);
    //   setTimeout(() => {
    //     dispatch(readMessage(chatUserDetails.id));
    //   }, 1500);
    //   setTimeout(() => {
    //     dispatch(receiveMessageFromUser(chatUserDetails.id));
    //   }, 2000);
    // }
    setReplyData(null);
  };

  useEffect(() => {
    // alert(active_conversation_user._id)
    socket?.emit('userOnline', user.id);

    // Listen for updates on online users
    socket?.on('updateOnlineUserStatus', (onlineUsers) => {
      // Update online users in your state or do any other necessary actions
      // console.log('Online Users:', onlineUsers);
    });
    console.log(active_conversation, 'active_conversation')
    socket?.on("conversationResponse", async (data: any) => {
      console.log(data.type === 'agency' && data.chat_id === active_conversation?._id, 'ehfwefyugweyfy')
      if (data._id) {
        if (active_conversation_user?.isCustom) {
          getConversation(`isCustom=${true}&_id=${active_conversation_user._id}`)
        } else if (active_conversation_user?.type == "user") {
          getConversation(`agency_id=${agency._id}&email=${user.email}`)

        } else {
          getConversation(`agency_id=${agency._id}&location_id=${param.locationId}`)
        }
      } else {

        if (data.type === 'agency' && data.chat_id === active_conversation?._id) {

          setUserConversation((prevUserConversation: { messages: any; }) => {
            return {
              ...prevUserConversation,
              messages: [...prevUserConversation?.messages, data],
            };
          });
        }
      }

      console.log(data, 'conversationResponse')
    });
    return () => {
      socket && socket.emit('userOffline', user.id);
      socket && socket.off("conversationResponse");
    };

  }, [socket, socket, active_conversation]);

  useEffect(() => {
    // getConversation(`_id=${active_conversation?._id}`)
    const email = window.location.pathname.split('/')[1]
    console.log(email, 'emailemail')
    if (active_conversation_user?.isCustom) {
      getConversation(`isCustom=${true}&_id=${active_conversation_user._id}`)
    } else if (active_conversation_user?.type == "user") {
      getConversation(`agency_id=${agency._id}&email=${email}`)

    } else {
      getConversation(`agency_id=${agency._id}&location_id=${param.locationId}`)
    }
  }, [active_conversation_user]);
  useEffect(() => {
    setUserConversation(active_conversation)
  }, [
    active_conversation
  ]);

  const onDeleteMessage = (messageId: string | number) => {
    dispatch(deleteMessage(chatUserDetails.id, messageId));
  };

  const onDeleteUserMessages = () => {
    dispatch(deleteUserMessages(chatUserDetails.id));
  };

  const onToggleArchive = () => {
    dispatch(toggleArchiveContact(chatUserDetails.id));
  };

  return (
    <>
      <UserHead
        chatUserDetails={active_conversation_user}
        pinnedTabs={pinnedTabs}
        onOpenUserDetails={onOpenUserDetails}
        onDelete={onDeleteUserMessages}
        isChannel={false}
        onToggleArchive={onToggleArchive}
      />
      <Conversation
        chatUserConversations={UserConversation}
        chatUserDetails={active_conversation_user}
        onDelete={onDeleteMessage}
        onSetReplyData={onSetReplyData}
        isChannel={false}
      />
      <ChatInputSection
        onSend={onSend}
        replyData={replyData}
        onSetReplyData={onSetReplyData}
        chatUserDetails={active_conversation_user}
      />
    </>
  );
};

export default Index;
